<template>
	<div :class="$friends.loyalty">
		<div :class="$friends.title">
			<router-link to="/personal/loyalty">
				<img src="~@/assets/img/Personal/Menu/arrow.svg"/>
			</router-link>
			Список друзей
		</div>
		<div :class="$friends.main">
			<hr>
			<div :class="$friends.littleTitle">История регистраций</div>
			<History
				:class="$friends.history"
				:totalPages="pageCount"
				:transactions="history"
				:friends="true"
				@updateList="load($event)"
			/>
		</div>
	</div>
</template>
<script>


import axios from 'axios';
import History from '@/components/block/Personal/TransactionHistory.vue'
import qs from "qs";

export default {
	components: {History},
	data()
	{
		return {
			pageCount: 1,
			history: []
		}
	},
	methods: {
		async load(payload = {page: 1, sort: 'data', desc: true})
		{
			let resp = await axios.post('/personal/getFriends/', qs.stringify(payload))
			this.history = resp.data.history
			this.pageCount = resp.data.pageCount
			this.$store.state.personal.msg = resp.data.msg
		}
	},
	mounted()
	{
		this.load();
	}
}
</script>

<style
	module="$friends"
	lang="scss"
>
.loyalty
{
	border-radius: 10px;
	padding: 32px 44px;
	background: #FFFFFF;
	box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 2px 14px rgba(0, 0, 0, 0.08);
	@media (max-width: 780px)
	{
		padding: 32px 22px;
	}
	@media (max-width: 420px)
	{
		padding: 16px 10px;
	}
}

.title
{
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 32px;

	a
	{
		margin-right: 25px;

		img
		{
			transform: rotate(180deg);
		}
	}
}

.main
{
	/*max-width: 678px;*/
	/*width: calc(100vw - 80px);*/
	@media (max-width: 420px)
	{
		width: calc(100vw - 50px);
	}

	hr
	{
		border: none;
		background-color: $greyBorder;
		height: 1px;
		margin: 32px 0;
	}
}

.littleTitle
{
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 28px;
	padding-bottom: 15px;
}

.history
{
	overflow: auto;
}
</style>